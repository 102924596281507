<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="modalTitle"
    :modal-append-to-body="false"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
        <el-form-item label="菜单名称" prop="menuName">
          <el-input v-model="formModel.menuName" placeholder="请输入菜单名称" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="上级菜单" prop="parentId">
          <!-- <el-select
            v-model="formModel.parentId"
            filterable
            remote
            clearable
            placeholder="请输入关键词"
            :remote-method="queryMenu"
            style="width:300px"
          >
            <el-option
              v-for="menu in menuList"
              :key="menu.id"
              :label="menu.menuName + '(' + menu.sortNo + ')'"
              :value="menu.id"
            ></el-option>
          </el-select> -->
          <el-select-tree
            :props="props"
            :options="menuList"
            v-model="formModel.parentId"
            style="width:300px;"
            size="mediumn"
            height="200"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="排序号" prop="sortNo">
          <el-input v-model="formModel.sortNo" placeholder="请输入排序号" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="菜单地址" prop="menuUrl">
          <el-input v-model="formModel.menuUrl" placeholder="请输入菜单地址" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="图标样式" prop="icon">
          <el-input v-model="formModel.icon" placeholder="请输入图标样式" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="菜单类型" prop="menuType">
          <el-select v-model="formModel.menuType">
            <el-option label="后端接口" value="1"></el-option>
            <el-option label="前端页面" value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>    
    <el-dialog
        width="30%"
        title="内层 Dialog"
        :visible.sync="innerVisible"
        append-to-body>
      </el-dialog>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import menuApi from "@/api/sys/menu";
import SelectTree from "@/components/SelectTree";

export default {
  props: ["menuId","modalTitle","parentMenu"],
  data() {
    return {
      formModel : {},
      innerVisible: false,
      ruleValidate: {
        menuName: [
          { required: true, message: "菜单名称不能为空", trigger: "blur" }
        ],
        sortNo: [
          { required: true, message: "排序号不能为空", trigger: "blur" }
        ],
        menuUrl: [
          { required: true, message: "菜单地址不能为空", trigger: "blur" }
        ],
        menuType: [
          {
            required: true,
            message: "菜单类型不能为空",
            trigger: "blur"
          }
        ]
      },
      menuList:[],
      props: {
        // 配置项（必选）
        value: "id",
        label: "menuName"
      },
      loading: false,
      submitting: false,
      showDialog: true
    };
  },
  computed: {
    menuListFilter () {
      var self = this;

      var list = [];

      var menuMap = {};

      self.menuList.forEach((menu)=>{
        menuMap[menu.id] = menu;
      });

      for(var key in menuMap){
        list.push(menuMap[key]);
      }

      return list;
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close",false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return menuApi.add(self.formModel);
            } else {
              return menuApi.update(self.formModel);
            }
          })().then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close",true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              self.$emit("close",false);
            }
          });
        } else {
          this.$emit("error");
        }
      });
    },
    queryMenu(keywords,limit) {
      var formData = new FormData();
      formData.append("keywords",keywords);
      formData.append("excludeId",this.formModel.id);

      console.log("limit=" + limit);
      
      formData.append("limit",limit | 20); //查询

      return menuApi.query(formData).then(response=>{
        var jsonData = response.data;

        if(jsonData.result){
          this.menuList = jsonData.data;
        }
        else{
          this.$message.error(jsonData.message + "");
        }
      });
    }
  },
  async mounted() {
    var self = this;
    self.loading = true;

    //查询足够多的菜单，后续就可以通过parentId直接赋值
    await this.queryMenu('',1000);

    (function(){
      if(self.menuId.length==0){
        return menuApi.create()
      }
      else{
        return menuApi.edit(self.menuId)
      }
    })().then(response => {
      var jsonData = response.data;
      self.loading = false;

      if (jsonData.result) {
        self.formModel = jsonData.data;

        // 增加初始值
        if(jsonData.data.parentId!=null){
          console.log("menuList.length=" + self.menuList.length);
          console.log("jsonData.data.parentId=" + jsonData.data.parentId);

          self.formModel.parentId = jsonData.data.parentId;
          // self.menuList.push({
          //   id: jsonData.data.parentId,
          //   menuName: jsonData.data.parentName
          // });
        }
        else if(this.parentMenu!=null && this.parentMenu.id!=null){
          self.formModel.parentId = this.parentMenu.id; 

          // self.menuList.push({
          //   id: this.parentMenu.id,
          //   menuName: this.parentMenu.menuName
          // });
        }
      } else {
        self.$message.error(jsonData.message + "");
      }
    }).catch(error => {
      self.$message.error(error + "");
    });
  },
  components: {
    "el-select-tree": SelectTree
  }
};
</script>